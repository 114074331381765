(() => {
  if (window.customElements.get('pr-the-footer')) return;

  window.customElements.define(
    'pr-the-footer',
    class extends HTMLElement {
      connectedCallback() {}
    }
  );
})();
